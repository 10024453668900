@import url("https://fonts.googleapis.com/css2?family=Lemon&family=Quicksand:wght@600&display=swap");

.breweryCard {
  margin: 10px;
  padding: 10px;
}

.breweryDiv {
  margin: 10px 0px;
  padding: 10px 0px;
  height: 100%;
  background-color: #eee;
}

.breweryDiv h1 {
  color: forestgreen;
  padding: 10px;
  font-family: 'Quicksand', Arial, Helvetica, sans-serif;
}

.title {
  display: flex;
}

.title img {
  margin-left: 10px;
}

.colorNav Button {
  color: forestgreen;
  border-color: forestgreen;
}

.navBrand {
  color: forestgreen !important;
}

.mapContainer {
  margin: 0 auto;
  width: 95%;
  height: 400px;
  border: 1px solid blue;
}

.favDiv {
  margin: 10px 0px;
  padding: 10px 0px;
  height: 100%;
  background-color: #eee;
  background-repeat: no-repeat;
}

.favDiv h1 {
  color: forestgreen;
  padding: 10px;
  font-family: 'Quicksand', Arial, Helvetica, sans-serif;
}

.button {
  display:inline-block;
  margin: 5px;
  padding: 5px 10px;
}