@import url("https://fonts.googleapis.com/css2?family=Lemon&family=Quicksand:wght@600&display=swap");

.beerDiv {
  margin: 10px 0px;
  padding: 10px 0px;
  height: 100%;
  background-color: #eee;
  background-repeat: no-repeat;
}

.beerDiv h1 {
  color: forestgreen;
  padding: 10px;
  font-family: 'Quicksand', Arial, Helvetica, sans-serif;
}

.beerCard {
  margin: 10px;
  padding: 10px;
}

.breweryCard {
  margin: 10px;
  padding: 10px;
}

.colorNav Button {
  color: forestgreen;
  border-color: forestgreen;
}

.navBrand {
  color: forestgreen !important;
}

.beerImage {
  max-height: 600px;
  width: 100%;
}

.wrapper {
  display: flex;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}

.left {
  width: 80%;
}

.right {
  display: flex;
  width: 20%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.description {
  max-height: 150px;
  overflow: auto;
}

.button {
  display:inline-block;
  margin: 5px;
  padding: 5px 10px;
}

.favDiv {
  margin: 10px 0px;
  padding: 10px 0px;
  height: 100%;
  background-color: #eee;
  background-repeat: no-repeat;
}

.favDiv h1 {
  color: forestgreen;
  padding: 10px;
  font-family: 'Quicksand', Arial, Helvetica, sans-serif;
}

.favDiv h2 {
  color: forestgreen;
  padding: 10px;
  font-family: 'Quicksand', Arial, Helvetica, sans-serif;
}

.specialDiv {
  color: forestgreen;
  padding: 10px;
  font-size: 30px;
  font-family: 'Quicksand', Arial, Helvetica, sans-serif;
}

.specialButton {
  margin-left: 20px;
  border-radius: 5px;
}

.specialButton:link {
  border: forestgreen;
}

.specialButton:visited {
  border: forestgreen;
}

.specialButton:hover {
  border: forestgreen;
}

.specialButton:active {
  border: forestgreen;
}

.specialButton:focus {
  border: forestgreen;
}