@import url("https://fonts.googleapis.com/css2?family=Lemon&family=Quicksand:wght@600&display=swap");

.home {
  text-align: center;
  margin-top: 10px;
  background-color: #eee;
  background-repeat: no-repeat;
  height: 100vh
}

.home h1 {
  color: forestgreen;
  font-size: 65px;
  font-family: 'Lemon', cursive;;
  margin-bottom: 20px;
}

.home h3 {
  color: forestgreen;
  font-size: 35px;
  font-family: 'Quicksand', Arial, Helvetica, sans-serif;
}

header {
  padding: 10px;
}

.banner {
  max-width: 95%;
  border-radius: 5px;
  max-height: 65%;
}

.navBrand {
  color: forestgreen !important;
}
