@import url(https://fonts.googleapis.com/css2?family=Lemon&family=Quicksand:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lemon&family=Quicksand:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lemon&family=Quicksand:wght@600&display=swap);
.Beers_beerDiv__2T_S1 {
  margin: 10px 0px;
  padding: 10px 0px;
  height: 100%;
  background-color: #eee;
  background-repeat: no-repeat;
}

.Beers_beerDiv__2T_S1 h1 {
  color: forestgreen;
  padding: 10px;
  font-family: 'Quicksand', Arial, Helvetica, sans-serif;
}

.Beers_beerCard__2BiuU {
  margin: 10px;
  padding: 10px;
}

.Beers_breweryCard__1__XD {
  margin: 10px;
  padding: 10px;
}

.Beers_colorNav__7Pbv4 Button {
  color: forestgreen;
  border-color: forestgreen;
}

.Beers_navBrand__1nHcO {
  color: forestgreen !important;
}

.Beers_beerImage__1MN71 {
  max-height: 600px;
  width: 100%;
}

.Beers_wrapper__d1TIb {
  display: flex;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}

.Beers_left__1i3wH {
  width: 80%;
}

.Beers_right__1qHIz {
  display: flex;
  width: 20%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.Beers_description__kL7TS {
  max-height: 150px;
  overflow: auto;
}

.Beers_button__G7n5Q {
  display:inline-block;
  margin: 5px;
  padding: 5px 10px;
}

.Beers_favDiv__36sG1 {
  margin: 10px 0px;
  padding: 10px 0px;
  height: 100%;
  background-color: #eee;
  background-repeat: no-repeat;
}

.Beers_favDiv__36sG1 h1 {
  color: forestgreen;
  padding: 10px;
  font-family: 'Quicksand', Arial, Helvetica, sans-serif;
}

.Beers_favDiv__36sG1 h2 {
  color: forestgreen;
  padding: 10px;
  font-family: 'Quicksand', Arial, Helvetica, sans-serif;
}

.Beers_specialDiv__1l8xD {
  color: forestgreen;
  padding: 10px;
  font-size: 30px;
  font-family: 'Quicksand', Arial, Helvetica, sans-serif;
}

.Beers_specialButton__V2ecc {
  margin-left: 20px;
  border-radius: 5px;
}

.Beers_specialButton__V2ecc:link {
  border: forestgreen;
}

.Beers_specialButton__V2ecc:visited {
  border: forestgreen;
}

.Beers_specialButton__V2ecc:hover {
  border: forestgreen;
}

.Beers_specialButton__V2ecc:active {
  border: forestgreen;
}

.Beers_specialButton__V2ecc:focus {
  border: forestgreen;
}
.Breweries_breweryCard__wyYsy {
  margin: 10px;
  padding: 10px;
}

.Breweries_breweryDiv__2VkWy {
  margin: 10px 0px;
  padding: 10px 0px;
  height: 100%;
  background-color: #eee;
}

.Breweries_breweryDiv__2VkWy h1 {
  color: forestgreen;
  padding: 10px;
  font-family: 'Quicksand', Arial, Helvetica, sans-serif;
}

.Breweries_title__Npp1v {
  display: flex;
}

.Breweries_title__Npp1v img {
  margin-left: 10px;
}

.Breweries_colorNav__2Qi0O Button {
  color: forestgreen;
  border-color: forestgreen;
}

.Breweries_navBrand__i_M0I {
  color: forestgreen !important;
}

.Breweries_mapContainer__3OWLy {
  margin: 0 auto;
  width: 95%;
  height: 400px;
  border: 1px solid blue;
}

.Breweries_favDiv__3QLNy {
  margin: 10px 0px;
  padding: 10px 0px;
  height: 100%;
  background-color: #eee;
  background-repeat: no-repeat;
}

.Breweries_favDiv__3QLNy h1 {
  color: forestgreen;
  padding: 10px;
  font-family: 'Quicksand', Arial, Helvetica, sans-serif;
}

.Breweries_button__iOI8f {
  display:inline-block;
  margin: 5px;
  padding: 5px 10px;
}
.Home_home__1ueQ0 {
  text-align: center;
  margin-top: 10px;
  background-color: #eee;
  background-repeat: no-repeat;
  height: 100vh
}

.Home_home__1ueQ0 h1 {
  color: forestgreen;
  font-size: 65px;
  font-family: 'Lemon', cursive;;
  margin-bottom: 20px;
}

.Home_home__1ueQ0 h3 {
  color: forestgreen;
  font-size: 35px;
  font-family: 'Quicksand', Arial, Helvetica, sans-serif;
}

header {
  padding: 10px;
}

.Home_banner__2aEcl {
  max-width: 95%;
  border-radius: 5px;
  max-height: 65%;
}

.Home_navBrand__1i5nh {
  color: forestgreen !important;
}

